
import { defineComponent, nextTick, ref } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: { TmDropdownItem, TmScrollbar, TmButton, TmDropdown },
  setup() {
    const testing = ref(false)
    const samples = [
      ['top-left', 'top', 'top-right'],
      ['left-top', 'right-top'],
      ['left', 'right'],
      ['left-bottom', 'right-bottom'],
      ['bottom-left', 'bottom', 'bottom-right'],
    ]
    const scroll = ref()
    const centering = () => {
      const { scrollWidth, offsetWidth, scrollHeight, offsetHeight } = scroll.value.$el
      scroll.value.$el.scrollLeft = (scrollWidth - offsetWidth) / 2
      scroll.value.$el.scrollTop = (scrollHeight - offsetHeight) / 2
    }

    const toggleTesting = async () => {
      testing.value = !testing.value
      await nextTick()
      centering()
    }

    const loading = ref(false)
    const btn1El = ref()
    const btn2El = ref()
    const targetEl = ref()
    const showDropdown = ref(false)
    const toggleMenu = (target: string) => {
      targetEl.value = target === 'btn1El' ? btn1El.value : btn2El.value
      showDropdown.value = !showDropdown.value
    }

    return {
      btn1El,
      btn2El,
      targetEl,
      showDropdown,
      toggleMenu,
      loading,
      testing,
      samples,
      scroll,
      toggleTesting,
    }
  },
})
